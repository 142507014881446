import React, { } from "react"
import "../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import ExpoBooths from "../../components/expobooths/ExpoBooths";
import { Helmet } from "react-helmet"

const favicon_logo = require("../../images/favicon.png");

const DevelopersPage = () => (
  <>
    <Favicon url={favicon_logo} />

    <Helmet>
      <meta charSet="utf-8" />
      <title>Propverse - Powered by Propex.AI</title>
      <link rel="canonical" href="http://qa.propverse.ai/" />
    </Helmet>

    <ExpoBooths />
  </>
)

export default DevelopersPage
